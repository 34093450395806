import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Novel = (key) => {
    return (
        <Container.Fullwidth key={key.toString() + 'slider'}>
            <Container.Novel id={key} sliderTitleId="nodelTitle">
                <div className="bg-grey">
                    <p>1949–heute München – Maximilianeum</p>
                    <h2>Im Herbst 1945 im kriegszerstörten München...</h2>
                    <StaticImage src="../../../../static/images/Muenchen-Maximilianeum/Novel/Szene_1_Bild_1.png" placeholder="none"  alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Muenchen-Maximilianeum/Novel/Szene_1_Bild_2.png" placeholder="none"  alt=""/>
                <div className="bg-grey">
                    <h2>Bald darauf machen sich die Architekten an die Arbeit …</h2>
                    <StaticImage src="../../../../static/images/Muenchen-Maximilianeum/Novel/Szene_2_Bild_1.png" placeholder="none"  alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Muenchen-Maximilianeum/Novel/Szene_2_Bild_2.png" placeholder="none"  alt=""/>
                <div className="bg-grey">
                    <h2>Und bis heute schlägt im Maximilianeum
                        das Herz der bayerischen Demokratie …</h2>
                    <StaticImage src="../../../../static/images/Muenchen-Maximilianeum/Novel/Szene_3_Bild_1.png" placeholder="none"  alt=""/>
                </div>
                <StaticImage src="../../../../static/images/Muenchen-Maximilianeum/Novel/Szene_3_Bild_2.png" placeholder="none" alt=""/>
            </Container.Novel>
        </Container.Fullwidth>
    )
}

export default Novel

