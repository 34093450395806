import * as React from 'react'
import * as Container from "../../container/container";

const TextBlock1blue = (key) => {
    return (
        <Container.Part background="blue" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>„Dem Landtag ist im Maximilianeum <br/> <span>&nbsp;</span> ein würdiges Heim bereitet worden.“
                    </h2>
                    <div className="inner-container">
                        <p>
                            Das Maximilianeum ist seit 1949 Sitz des bayerischen Parlaments. Im Zuge des demokratischen
                            Neuanfangs war man auf der Suche nach einem Tagungsort für den Landtag. Das von 1819 bis
                            1933 als Landtag genutzte Gebäude in der Prannerstraße war im Zweiten Weltkrieg schwer
                            beschädigt worden. Im zerstörten München waren kaum nutzbare Räume zu finden. Übergangsweise
                            tagte der Landtag in der Ludwig-Maximilians-Universität, im Brunnenhoftheater der Residenz
                            und im Sophiensaal der Oberfinanzdirektion. Bei der Suche nach einem dauerhaften Ort fiel
                            die Wahl schließlich auf das weitgehend unbeschädigte Maximilianeum.
                        </p>
                        <p>
                            Das Gebäude war von 1857 bis 1874 im Auftrag von König Maximilian II. von dem Architekten
                            Friedrich Bürklein als Ort der historischen und künstlerischen Bildung erbaut worden. Es
                            beherbergte eine Gemäldegalerie und die Studienstiftung Maximilianeum für Hochbegabte. Noch
                            heute ist das Bauwerk im Besitz der Stiftung und dient auch als Wohnstätte für
                            Stipendiatinnen und Stipendiaten. Der Bayerische Landtag ist Dauermieter und übernahm die
                            Instandsetzung sowie Erweiterungsbaumaßnahmen.
                        </p>

                        <Container.More move="true" lessText="weniger lesen" moreText="weiter lesen">

                            <p>
                                Um ideale Arbeitsbedingungen für das Parlament zu schaffen, mussten neben dem Plenarsaal
                                und dem Senatssaal zahlreiche weitere Räume wie Fraktionssäle und Konferenzzimmer,
                                Verwaltungs-, Archiv- und Bibliotheksräume geschaffen werden. Im Sommer 1946 beschloss
                                der Ministerrat, sofort mit den notwendigen Bauarbeiten zu beginnen – allerdings ohne
                                Rücksprache mit der Gebäudeeigentümerin, der Stiftung Maximilianeum. Man einigte sich
                                schließlich auf eine gemeinsame Nutzung des Gebäudes. Der Staat schloss einen
                                Mietvertrag mit der Stiftung. Aus dem „Nationalbau“ aus der Zeit der Monarchie wurde der
                                zentrale Ort für die parlamentarische Demokratie in Bayern.
                            </p>
                        </Container.More>
                    </div>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock1blue
