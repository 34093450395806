import * as React from 'react'
import {Link} from "gatsby"
import * as Container from "../../container/container";
import {StaticImage} from "gatsby-plugin-image";

const TextBlock2White = (key, ortName) => {
    return (
        <Container.Part background="white" key={key}>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-Maximilianeum/maximilianeum_bild_0003.jpg"}
                           data-glightbox="title: Abgeordnete der ersten Wahlperiode mit Dr. Wilhelm Hoegner (zweite Reihe, links), 1949/50; description: .custom-desc1_3; descPosition: bottom;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/maximilianeum_bild_0003.jpg"
                                width={335} layout="fixed" placeholder="none"
                                alt="Abgeordnete der ersten Wahlperiode mit Dr. Wilhelm Hoegner (zweite Reihe, links), 1949/50"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_3">
                            <p>
                                Wilhelm Hoegner, der von den Amerikanern eingesetzte Ministerpräsident, reklamiert die
                                Idee für den Einzug des Landtags ins Maximilianeum für sich. In seinen Erinnerungen
                                schreibt er: „Die Isar rauschte, hoch über ihr prangte das Maximilianeum, das Gold an
                                den Fresken leuchtete, und mir kam plötzlich der Gedanke, dem künftigen bayerischen
                                Parlament diesen erhabenen Bau einzuräumen.“
                            </p>
                            <p className="copy">
                                Abb. Bildarchiv Bayerischer Landtag
                            </p>
                        </div>
                        <figcaption>
                            Abgeordnete der ersten Wahlperiode mit Dr. Wilhelm Hoegner (zweite Reihe, links), 1949/50
                            <span className="description">
                                Wilhelm Hoegner, der von den Amerikanern eingesetzte Ministerpräsident, reklamiert die
                                Idee für den Einzug des Landtags ins Maximilianeum für sich. In seinen Erinnerungen
                                schreibt er: „Die Isar rauschte, hoch über ihr prangte das Maximilianeum, das Gold an
                                den Fresken leuchtete, und mir kam plötzlich der Gedanke, dem künftigen bayerischen
                                Parlament diesen erhabenen Bau einzuräumen.“
                                </span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-Maximilianeum/maximilianeum_bild_0005.jpg"}
                           data-glightbox="title: Fest zum 100-jährigen Bestehen der Studienstiftung Maximilianeum, 1953; description: .custom-desc1_5; descPosition: bottom;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/maximilianeum_bild_0005.jpg"
                                width={335} layout="fixed" placeholder="none"
                                alt="Fest zum 100-jährigen Bestehen der Studienstiftung Maximilianeum, 1953"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_5">
                            <p>
                                1953 feierte die Studienstiftung Maximilianeum im Plenarsaal ihr Jubiläum. Vor den
                                Wandteppich mit dem Staatswappen wurde zu diesem Anlass ein Gemälde des Stifters, König
                                Maximilian II., gehängt.
                            </p>
                            <p className="copy">
                                Abb. Bayerische Staatsbibliothek München, Fotoarchiv Timpe
                            </p>
                        </div>
                        <figcaption>
                            Fest zum 100-jährigen Bestehen der Studienstiftung Maximilianeum, 1953
                            <span className="description">
                                1953 feierte die Studienstiftung Maximilianeum im Plenarsaal ihr Jubiläum. Vor den
                                Wandteppich mit dem Staatswappen wurde zu diesem Anlass ein Gemälde des Stifters, König
                                Maximilian II., gehängt.
                                </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-Maximilianeum/maximilianeum_bild_0004.jpg"}
                           data-glightbox="title: Plan für den Umbau des 2. Stocks im Maximilianeum, 1948; description: .custom-desc1_4; descPosition: bottom;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/maximilianeum_bild_0004.jpg"
                                width={335} layout="fixed" placeholder="none"
                                alt="Plan für den Umbau des 2. Stocks im Maximilianeum, 1948"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_4">
                            <p>
                                Ab 1947 erfolgte durch das Universitätsbauamt und den Architekten Carl Kerge der
                                Wiederaufbau und Umbau des Maximilianeums.
                                Der Plan zeigt auch den Senatssaal, der von 1949 bis zur Auflösung des Senats 1999 als
                                Sitzungssaal für die Ständekammer genutzt wurde. Heute dient der Saal als
                                Veranstaltungsraum.
                            </p>
                            <p className="copy">Abb. Bildarchiv Bayerischer Landtag</p>
                        </div>
                        <figcaption>
                            Plan für den Umbau des 2. Stocks im Maximilianeum, 1948
                            <span className="description">
                                Ab 1947 erfolgte durch das Universitätsbauamt und den Architekten Carl Kerge der
                                Wiederaufbau und Umbau des Maximilianeums.
                                Der Plan zeigt auch den Senatssaal, der von 1949 bis zur Auflösung des Senats 1999 als
                                Sitzungssaal für die Ständekammer genutzt wurde. Heute dient der Saal als
                                Veranstaltungsraum.
                                </span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-Maximilianeum/maximilianeum_bild_0008.jpg"}
                           data-glightbox="title: Sitzordnung im Plenarsaal des Bayerischen Landtags;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/maximilianeum_bild_0008.jpg"
                                width={335} layout="fixed" placeholder="none"
                                alt="Grafik Sitzordnung im Plenarsaal des Bayerischen Landtags"/>
                        </a>
                        <figcaption>
                            Sitzordnung im Plenarsaal des Bayerischen Landtags
                        </figcaption>
                    </figure>
                    <Container.If env="w">
                        <p>
                            <Link to="https://www.bayern.landtag.de/maximilianeum/saeleraeume/plenarsaal/"
                                  className="icon-arrow_right icon-bg">
                                Zur aktuellen Sitzordnung des Bayerischen Landtages
                            </Link>
                        </p>
                    </Container.If>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery1"
                           href={"/images/Muenchen-Maximilianeum/maximilianeum_bild_0006.jpg"}
                           data-glightbox="title: Einweihungsfeier des umgebauten Plenarsaals am 13. Dezember 2005; description: .custom-desc1_6; descPosition: bottom;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/maximilianeum_bild_0006.jpg"
                                width={335} layout="fixed" placeholder="none"
                                alt="Einweihungsfeier des umgebauten Plenarsaals am 13. Dezember 2005"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_6">
                            <p>
                                Der damalige Landtagspräsident Alois Glück eröffnete 2005 den neuen Plenarsaal. Die
                                Neugestaltung verbesserte die Arbeitsbedingungen und schuf neue technische Bedingungen.
                                Kameras des Landtagsamts und des Bayerischen Rundfunks sind über den Regierungsbänken
                                installiert und ermöglichen die Übertragung der Sitzungen im Internet.
                            </p>
                            <p className="copy">Abb. Bildarchiv Bayerischer Landtag, AA09434A</p>
                        </div>
                        <figcaption>
                            Einweihungsfeier des umgebauten Plenarsaals am 13. Dezember 2005
                            <span className="description">
                                Der damalige Landtagspräsident Alois Glück eröffnete 2005 den neuen Plenarsaal. Die
                                Neugestaltung verbesserte die Arbeitsbedingungen und schuf neue technische Bedingungen.
                                Kameras des Landtagsamts und des Bayerischen Rundfunks sind über den Regierungsbänken
                                installiert und ermöglichen die Übertragung der Sitzungen im Internet.
                                </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock2White
