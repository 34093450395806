import * as React from 'react'
import * as Container from "../../container/container";
import {StaticImage} from "gatsby-plugin-image";

const TextBlock3Parallax = (key, ortName) => {
    return (
        <Container.Part background="bg-img parallax-bg parallax-maximilianeum" key={key}>
            <Container.Row>
                <div className="col-12">
                    <h2>Die Arbeit des Bayerischen Landtags</h2>
                </div>
            </Container.Row>
            <Container.Row>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-1.png"}
                           data-glightbox="title: Schwurhand; description: .custom-desc1_7; descPosition: bottom;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-1.png"
                                width={335} layout="fixed" placeholder="none"
                                alt="Schwurhand"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_7">
                            <p>
                                Nach der Wahl tritt der Landtag zusammen und wählt eine Ministerpräsidentin oder einen
                                Ministerpräsidenten. Die Mitglieder der Staatsregierung werden dann von der
                                Ministerpräsidentin oder vom Ministerpräsidenten berufen. Sie alle legen einen Eid ab.
                            </p>
                            <p className="copy"></p>
                        </div>
                        <figcaption>
                            Schwurhand
                            <span className="description">
                                Nach der Wahl tritt der Landtag zusammen und wählt eine Ministerpräsidentin oder einen
                                Ministerpräsidenten. Die Mitglieder der Staatsregierung werden dann von der
                                Ministerpräsidentin oder vom Ministerpräsidenten berufen. Sie alle legen einen Eid ab.
                                    </span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-3.png"}
                           data-glightbox="title: Haushaltsausschuss; description: .custom-desc1_9; descPosition: bottom;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-3.png"
                                width={335} layout="fixed" placeholder="none"
                                alt="Haushaltsausschuss"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_9">
                            <p>
                                Große Teile der Parlamentsarbeit werden in 14 ständigen Ausschüssen erledigt. Jeder
                                Ausschuss ist für die Dauer einer Wahlperiode für ein bestimmtes Politikfeld zuständig,
                                auf das sich seine Mitglieder spezialisieren. Die Ausschüsse bilden gewissermaßen im
                                Kleinen das Parlament nach – inklusive der Mehrheitsverhältnisse. Sie beraten
                                Gesetzentwürfe und Anträge und bereiten so die Sitzungen und Entscheidungen der
                                Vollversammlung vor. Der Ausschuss für Staatshaushalt und Finanzfragen zum Beispiel
                                berät den Staatshaushalt.
                            </p>
                            <p className="copy"></p>
                        </div>
                        <figcaption>
                            Haushaltsausschuss
                            <span className="description">
                                Große Teile der Parlamentsarbeit werden in 14 ständigen Ausschüssen erledigt. Jeder
                                Ausschuss ist für die Dauer einer Wahlperiode für ein bestimmtes Politikfeld zuständig,
                                auf das sich seine Mitglieder spezialisieren. Die Ausschüsse bilden gewissermaßen im
                                Kleinen das Parlament nach – inklusive der Mehrheitsverhältnisse. Sie beraten
                                Gesetzentwürfe und Anträge und bereiten so die Sitzungen und Entscheidungen der
                                Vollversammlung vor. Der Ausschuss für Staatshaushalt und Finanzfragen zum Beispiel
                                berät den Staatshaushalt.
                                    </span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-6.png"}
                           data-glightbox="title: Schulklasse; description: .custom-desc1_12; descPosition: bottom;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-6.png"
                                width={335} layout="fixed" placeholder="none"
                                alt="Schulklasse"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_12">
                            <p>
                                Der Bayerische Landtag ist ein offenes Haus für alle Bürgerinnen und Bürger. Denn
                                Demokratie muss sichtbar und erlebbar sein.
                            </p>
                            <p className="copy"></p>
                        </div>
                        <figcaption>
                            Schulklasse
                            <span className="description">
                                Der Bayerische Landtag ist ein offenes Haus für alle Bürgerinnen und Bürger. Denn
                                Demokratie muss sichtbar und erlebbar sein.
                                    </span>
                        </figcaption>
                    </figure>
                </div>
                <div className="col-6">
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-2.png"}
                           data-glightbox="title: Abgeordnete auf dem Weg in den Plenarsaal; description: .custom-desc1_8; descPosition: bottom;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-2.png"
                                width={335} layout="fixed" placeholder="none"
                                alt="Abgeordnete auf dem Weg in den Plenarsaal"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_8">
                            <p>
                                Alle fünf Jahre wird der Bayerische Landtag neu gewählt. Das System aus Direkt- und
                                Listenmandaten soll sicherstellen, dass der Wille der Bevölkerung bestmöglich abgebildet
                                wird.
                            </p>
                            <p className="copy"></p>
                        </div>
                        <figcaption>
                            Abgeordnete auf dem Weg in den Plenarsaal
                            <span className="description">
                                Alle fünf Jahre wird der Bayerische Landtag neu gewählt. Das System aus
                                Direkt- und Listenmandaten soll sicherstellen, dass der Wille der Bevölkerung bestmöglich
                                abgebildet wird.
                                    </span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-4.png"}
                           data-glightbox="title: Abstimmung über Gesetz; description: .custom-desc1_10; descPosition: bottom;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-4.png"
                                width={335} layout="fixed" placeholder="none"
                                alt="Abstimmung über Gesetz"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_10">
                            <p>
                                Gesetze können im Freistaat Bayern von der Staatsregierung, den Fraktionen, allen
                                Mitgliedern des Bayerischen Landtags oder vom Volk per Volksbegehren vorgeschlagen
                                werden. In mehreren Schritten beraten dann die Vollversammlung des Landtags und die
                                Ausschüsse und erst mit Zustimmung der Vollversammlung in der Schlussabstimmung kann ein
                                Gesetz beschlossen werden.
                            </p>
                            <p className="copy"></p>
                        </div>
                        <figcaption>
                            Abstimmung über Gesetz
                            <span className="description">
                                Gesetze können im Freistaat Bayern von der Staatsregierung, den Fraktionen, allen
                                Mitgliedern des Bayerischen Landtags oder vom Volk per Volksbegehren vorgeschlagen
                                werden. In mehreren Schritten beraten dann die Vollversammlung des Landtags und die
                                Ausschüsse und erst mit Zustimmung der Vollversammlung in der Schlussabstimmung kann ein
                                Gesetz beschlossen werden.
                                    </span>
                        </figcaption>
                    </figure>
                    <figure className="lightbox">
                        <a className="glightbox" data-gallery="gallery2"
                           href={"/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-5.png"}
                           data-glightbox="title: Petent vor dem Ausschuss; description: .custom-desc1_11; descPosition: bottom;">
                            <StaticImage
                                src="../../../../static/images/Muenchen-Maximilianeum/Comicbilder/GN_Max2_210818-5.png"
                                width={335} layout="fixed" placeholder="none"
                                alt="Petent vor dem Ausschuss"/>
                        </a>
                        <div className="glightbox-desc custom-desc1_11">
                            <p>
                                Die Bayerische Verfassung gibt allen Bewohnerinnen und Bewohnern Bayerns das Recht, sich
                                mit Bitten oder Beschwerden („Petitionen“) direkt an den Bayerischen Landtag zu wenden.
                                Manche Petitionen werden von vielen Tausend Menschen gemeinsam eingereicht, wie 2015
                                eine Petition zur Abschaffung der Studiengebühren. Interessant ist das Petitionsrecht
                                aber auch für Einzelpersonen, etwa wenn sie Streit mit Behörden (etwa über
                                Baugenehmigungen oder Steuerangelegenheiten) haben. Auch wenn kein Rechtsanspruch
                                vorliegt, tritt der Landtag häufig als Vermittler auf. Oft bekommen die Personen, die
                                eine Petition eingereicht haben, die Gelegenheit, sich vor dem Ausschuss zu äußern.
                            </p>
                            <p className="copy"></p>
                        </div>
                        <figcaption>
                            Petent vor dem Ausschuss
                            <span className="description">
                                Die Bayerische Verfassung gibt allen Bewohnerinnen und Bewohnern Bayerns das Recht, sich
                                mit Bitten oder Beschwerden („Petitionen“) direkt an den Bayerischen Landtag zu wenden.
                                Manche Petitionen werden von vielen Tausend Menschen gemeinsam eingereicht, wie 2015
                                eine Petition zur Abschaffung der Studiengebühren. Interessant ist das Petitionsrecht
                                aber auch für Einzelpersonen, etwa wenn sie Streit mit Behörden (etwa über
                                Baugenehmigungen oder Steuerangelegenheiten) haben. Auch wenn kein Rechtsanspruch
                                vorliegt, tritt der Landtag häufig als Vermittler auf. Oft bekommen die Personen, die
                                eine Petition eingereicht haben, die Gelegenheit, sich vor dem Ausschuss zu äußern.
                                    </span>
                        </figcaption>
                    </figure>
                </div>
            </Container.Row>
        </Container.Part>
    )
}

export default TextBlock3Parallax
