import * as React from 'react'
import * as Container from "../../container/container";
import { StaticImage } from "gatsby-plugin-image";

const Headerimage = (key, ortName) => {
    return (
        <Container.HeaderPart background="Maximilianeum" key={key}>
            <div className="headline">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>1949–heute:<br/>
                                München<br/>
                                Maximilianeum
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="box">
                            <ul>
                                <li>Parlamentarismus</li>
                                <li>Verfassung</li>
                                <li>Aushandlungsprozesse</li>
                                <li>Parteien</li>
                                <li>Wahlen</li>
                            </ul>
                            <p>
                                Das Maximilianeum ist als Sitz des Bayerischen Landtags seit 1949 der zentrale Ort der
                                parlamentarischen Demokratie in Bayern.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="image-row">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Muenchen-Maximilianeum/maximilianeum_bild_0002.jpg"}
                                   data-glightbox="title: Luftaufnahme Maximilianeum, 2016; description: .custom-desc1_1; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Muenchen-Maximilianeum/maximilianeum_bild_0002.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Luftaufnahme Maximilianeum, 2016"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_1">
                                    <p>
                                        Um den Anforderungen des Parlamentsbetriebs gerecht zu werden, wurde das Gebäude
                                        seit 1949 mehrfach renoviert und erweitert.
                                    </p>
                                    <p className="copy">Abb. Bildarchiv Bayerischer Landtag</p>
                                </div>
                                <figcaption>
                                    Luftaufnahme Maximilianeum, 2016
                                </figcaption>
                            </figure>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/Muenchen-Maximilianeum/maximilianeum_bild_0007.jpg"}
                                   data-glightbox="title: Der Plenarsaal bei der Pressekonferenz zum Einzug ins Maximilianeum am 10. Januar 1949; description: .custom-desc1_2; descPosition: bottom;">
                                    <StaticImage src="../../../../static/images/Muenchen-Maximilianeum/maximilianeum_bild_0007.jpg" width={335} layout="fixed" placeholder="none"
                                                 alt="Der Plenarsaal bei der Pressekonferenz zum Einzug ins Maximilianeum am 10. Januar 1949"/>
                                </a>
                                <div className="glightbox-desc custom-desc1_2">
                                    <p>
                                        Am 11. Januar 1949 konnte der Bayerische Landtag nach Abschluss der Baumaßnahmen
                                        mit einem Festakt ins Maximilianeum einziehen.
                                    </p>
                                    <p className="copy">Abb. Bildarchiv Bayerischer Landtag</p>
                                </div>
                                <figcaption>
                                    Der Plenarsaal bei der Pressekonferenz zum Einzug ins Maximilianeum am 10. Januar
                                    1949
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </Container.HeaderPart>
    )
}

export default Headerimage
